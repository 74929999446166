<template>
  <div class="ground-camera">
    <Transition name="slate" mode="out-in">
      <div v-show="!detailShow">
        <div
          class="data-page__title _flex _flex-justify__between _flex-align__center _font-weight__600 _font-size__heading3"
        >
          <span>
            <!-- Inspection Date: {{ dateArr[2] }} {{ dateArr[1] }} {{ dateArr[3] }} -->
          </span>
          <el-button
            type="info"
            :disabled="!Boolean(cameraImgList.filter((item) => item.checked).length)"
            @click="handleDown"
            >Download Images</el-button
          >
        </div>

        <el-form
          class="data-page__form-box"
          ref="form"
          :model="formData"
          inline
          label-position="left"
        >
          <el-form-item label="Camera">
            <el-select
              v-model="formData.camera"
              placeholder="All camera"
              clearable
              @change="getData"
            >
              <el-option v-for="item in cameraList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Date and Time">
            <SelectDate
              type="datetime"
              v-model="formData.beginTime"
              @change="getData"
              :disabledDate="disabledDate"
            ></SelectDate>
          </el-form-item>
        </el-form>

        <el-scrollbar
          class="ground-camera__list data-page__scrollbar"
          :style="{ height: scrollHeight + 'px' }"
        >
          <template v-if="cameraImgList && cameraImgList.length">
            <div
              v-for="item in cameraImgList"
              :key="item.id"
              class="list-item _flex _flex-align__center"
            >
              <el-checkbox v-model="item.checked"></el-checkbox>
              <el-image
                :src="item.filePath"
                fit="cover"
                @click="
                  detailData = item
                  detailShow = true
                "
              ></el-image>
              <div class="list-item__info">
                <div>
                  <span class="label">Sensor Name</span>
                  <span class="_font-weight__600">{{ resolution(item, 'camera') }}</span>
                </div>
                <div>
                  <span class="label">Created</span>
                  <span class="_font-weight__600">{{ resolution(item, 'createdTime') }}</span>
                </div>
              </div>
            </div>
          </template>
          <LoadingData v-else :loading="loading"></LoadingData>
        </el-scrollbar>
      </div>
    </Transition>

    <Transition name="slate" mode="out-in">
      <GroundCameraDetail v-show="detailShow" :detail="detailData" @change="detailShow = false" />
    </Transition>
  </div>
</template>

<script>
import { cameraFindList, groundCameraFindList } from '@/api/data'
import { batchDownload } from '@/utils/batch-save'
import SelectDate from '@/components/select-datetime/select-date'
import { parseTime } from '@/utils'
import { componentProp } from '../index-data'
import GroundCameraDetail from './ground-camera-detail'

export default {
  props: componentProp,
  components: {
    GroundCameraDetail,
    SelectDate
  },
  data() {
    return {
      loading: true,
      // 表单区域数据
      formData: {
        camera: '',
        type: 'ground',
        beginTime: ''
      },
      cameraList: [],
      cameraImgList: [],

      detailShow: false,
      detailData: {}
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.time) {
          this.formData.beginTime = `${val.time} 00:00:00`
        } else this.formData.beginTime = ''
      },
      immediate: true
    }
  },
  mounted() {
    cameraFindList({
      type: 2
    }).then((res) => {
      this.cameraList = res.result
    })
    this.getData()
  },
  methods: {
    disabledDate(time) {
      return this.$store.state.user.isOn
        ? !['2023-11-07', '2023-11-08'].includes(parseTime(time, '{y}-{m}-{d}'))
        : false
    },
    getData() {
      this.loading = true
      this.cameraImgList = []
      groundCameraFindList(this.formData)
        .then((res) => {
          this.loading = false
          this.cameraImgList = res.result.map((item) => {
            return {
              ...item,
              createdTime: `${parseTime(item.createdTime, 'reg')} ${parseTime(
                item.createdTime,
                '{h}:{i}:{s}'
              )}`,
              checked: false
            }
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleDown() {
      const cameraImg = this.cameraImgList.filter((item) => item.checked)
      const img = []
      if (cameraImg.length >= 1) {
        cameraImg.forEach((item) => {
          img.push(
            process.env.NODE_ENV === 'production' ? item.filePath : `file/${item.fileRelativePath}`
          )
        })
        batchDownload(img, 'Ground Camera')
      } else {
        this.$message.error('Please select a camera!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ground-camera {
  position: relative;

  > div {
    width: 100%;
  }

  &__list {
    height: 100%;

    .list-item {
      background: var(--color-white);
      padding-left: 60px;
      border-radius: 10px;
      position: relative;

      & + .list-item {
        margin-top: 30px;
      }

      > ::v-deep .el-checkbox {
        position: absolute;
        // 14px 为 checkbox 元素宽度
        left: calc(30px - 14px / 2);

        & .is-checked .el-checkbox__inner {
          border-color: var(--color-primary) !important;
        }
        & .el-checkbox__inner {
          border-width: 2px;
          border-style: solid;
          border-color: var(--color-info);
          &::after {
            top: 0.5px;
            left: 3px;
          }
        }
      }

      > .el-image {
        width: 305px;
        height: 170px;
        margin-right: 40px;
      }

      &__info {
        > div {
          font-size: 15px;

          & + div {
            margin-top: 15px;
          }

          .label {
            width: 100px;
            display: inline-block;
            margin-right: 40px;
          }
        }
      }
    }
  }
}
</style>
